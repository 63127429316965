<template>
    <div class="train-detail-content">
        <div class="train-detail-header">
            <el-breadcrumb separator-class="el-icon-arrow-right" class="interactive-breadcrumb">
                <el-breadcrumb-item class="first">实训管理</el-breadcrumb-item>
                <el-breadcrumb-item class="second">实训列表</el-breadcrumb-item>
                <el-breadcrumb-item class="third">实训详情</el-breadcrumb-item>
            </el-breadcrumb>
            <el-button type="primary" @click="goToTrainList">返回</el-button>
        </div>
        <div class="train-detail-body">
            <el-scrollbar :native="false">
                <div class="body-header">
                    <span class="text">{{trainDetailData.train_title}}</span>
                </div>
                <div class="task-info">
                    <ul>
                        <li>
                            <span>发布日期：</span>
                            <span>{{trainDetailData.create_time}}</span>
                        </li>
                        <li>
                            <span>需求类型：</span>
                            <span v-if="trainDetailData.type === 1">实训</span>
                            <span v-if="trainDetailData.type === 2">图文实训</span>
                            <span v-if="trainDetailData.type === 3">短视频实训</span>
                        </li>
                    </ul>
                </div>
                <div class="task-require">
                    <div class="title">
                        <span class="left-icon"></span>
                        <span class="text">{{trainDetailData.train_introduction.name}}</span>
                    </div>
                    <div class="require-content" v-html="trainDetailData.train_introduction.content"></div>
                </div>
                <div class="task-require">
                    <div class="title">
                        <span class="left-icon"></span>
                        <span class="text">{{trainDetailData.train_target.name}}</span>
                    </div>
                    <div class="require-content" v-html="trainDetailData.train_target.content"></div>
                </div>
                <div class="task-require">
                    <div class="title">
                        <span class="left-icon"></span>
                        <span class="text">{{trainDetailData.train_requirement.name}}</span>
                    </div>
                    <div class="require-content" v-html="trainDetailData.train_requirement.content"></div>
                </div>
                <div class="task-require" v-if="trainDetailData.train_determined.length === 1">
                    <div class="title">
                        <span class="left-icon"></span>
                        <span class="text">{{trainDetailData.train_determined_one.name}}</span>
                    </div>
                    <div class="require-content" v-html="trainDetailData.train_determined_one.content"></div>
                </div>
                <div class="task-require" v-if="trainDetailData.train_determined.length === 2">
                    <div class="title">
                        <span class="left-icon"></span>
                        <span class="text">{{trainDetailData.train_determined_two.name}}</span>
                    </div>
                    <div class="require-content" v-html="trainDetailData.train_determined_two.content"></div>
                </div>
                <div class="task-require">
                    <div class="title">
                        <span class="left-icon"></span>
                        <span class="text">{{trainDetailData.train_content.name}}</span>
                    </div>
                    <div class="require-content" v-html="trainDetailData.train_content.content"></div>
                </div>
                <div class="task-annex" v-if="trainDetailData.source_material.length > 0">
                    <div class="title">
                        <span class="left-icon"></span>
                        <span class="text">素材下载</span>
                    </div>
                    <ul class="annex-content">
                        <li v-for="item in trainDetailData.source_material">
                            <span class="file-name">
                                <i class="iconfont">&#xe66d;</i>
                                {{item.name}}
                            </span>
                            <div class="down-progress">
                                <div class="download" @click="downLoadTrainAnnex(item)">
                                    <span class="download-bg">
                                        <i class="iconfont">&#xe607;</i>
                                    </span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="task-annex" v-if="trainDetailData.script_id > 0">
                    <div class="title">
                        <span class="left-icon"></span>
                        <span class="text">关联脚本</span>
                    </div>
                    <div class="annex-content">
                        <div v-if="trainDetailData.scriptDetail" @click="viewScriptDetail" class="script-name">
                            {{trainDetailData.scriptDetail.name}}
                        </div>
                    </div>
                </div>
                <div class="train-evaluete">
                    <div class="title">
                        <span class="left-icon"></span>
                        <span class="text">实训评价</span>
                    </div>
                    <el-table :data="trainDetailData.train_evaluete" border style="width: 100%; flex: 1"
                              size="medium"
                              :header-cell-style="{fontWeight: 'normal', color: '#222', background: '#ECEBFF',height:'50px'}"
                              :cell-style="{fontSize: '14px',color: '#222'}">
                        <el-table-column prop="number" label="序号" align="center"></el-table-column>
                        <el-table-column prop="content" label="内容" align="center"></el-table-column>
                        <el-table-column prop="requirement" label="要求" align="center"></el-table-column>
                        <el-table-column prop="score" label="分值" align="center">
                            <template slot-scope="scope">
                                <span>{{scope.row.score}}分</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-scrollbar>
        </div>
        <!--脚本详情弹窗-->
        <el-dialog title="查看脚本" :visible.sync="dialogViewScript" width="66%" class="annex-list-dialog" :close-on-click-modal="false">
            <div class="view-script-main">
                <div class="view-script-item-top">
                    <div class="view-script-img">
                        <img :src="trainDetailData.scriptDetail.cover_url" alt="">
                    </div>
                    <div class="view-script-right">
                        <div class="view-script-name"><span>脚本名称：{{trainDetailData.scriptDetail.name}}</span></div>
                        <div class="view-script-fenlei"><span>脚本分类：{{trainDetailData.scriptDetail.classification_name}}</span></div>
                        <div class="view-script-jianjie" v-if="trainDetailData.scriptDetail.script_introduction"><span>脚本简介：{{trainDetailData.scriptDetail.script_introduction}}</span></div>
                    </div>
                </div>
                <div class="view-script-body" v-if="trainDetailData.scriptDetail.script_content !== '<p><br></p>'">
                    <div class="view-script-body-title">脚本内容</div>
                    <div class="view-script-body-content" v-html="trainDetailData.scriptDetail.script_content"></div>
                </div>
                <el-scrollbar class="script-dialog-table">
                    <div class="dialog-table-row">
                        <div class="dialog-table-column">镜头</div>
                        <div class="dialog-table-column">景别</div>
                        <div class="dialog-table-column">拍摄方向</div>
                        <div class="dialog-table-column">运镜</div>
                        <div class="dialog-table-column">拍摄内容</div>
                        <div class="dialog-table-column">音乐/字幕</div>
                        <div class="dialog-table-column">时间</div>
                    </div>
                    <div class="dialog-table-row" v-for="item in trainDetailData.scriptDetail.camera_lens">
                        <div class="dialog-table-column"><span class="text-overflow">{{item.number}}</span></div>
                        <div class="dialog-table-column"><span class="text-overflow">{{item.farewell}}</span></div>
                        <div class="dialog-table-column"><span class="text-overflow">{{item.direction}}</span></div>
                        <div class="dialog-table-column"><span class="text-overflow">{{item.mirror_movement}}</span></div>
                        <div class="dialog-table-column"><span class="text-overflow">{{item.content}}</span></div>
                        <div class="dialog-table-column"><span class="text-overflow">{{item.music_or_subtitle}}</span></div>
                        <div class="dialog-table-column"><span class="text-overflow">{{item.time}}</span></div>
                    </div>
                </el-scrollbar>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" class="determine-btn" @click="dialogViewScript = false">确 定</el-button>
                </span>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "TrainDetail",
        data() {
            return {
                //实训详情数据
                trainDetailData: {
                    train_title: '',
                    create_time: '',
                    type: null,
                    train_introduction: {
                        name: '',
                        content: ''
                    },
                    train_target: {
                        name: '',
                        content: ''
                    },
                    train_requirement: {
                        name: '',
                        content: '',
                    },
                    train_determined: [],
                    train_determined_one: {
                        name: '',
                        content: ''
                    },
                    train_determined_two: {
                        name: '',
                        content: ''
                    },
                    train_content: {
                        name: '',
                        content: ''
                    },
                    train_evaluete: [],
                    script_id: '',
                    scriptDetail: {
                        cover_url: '',
                        name: '',
                        classification_name: '',
                        script_introduction: '',
                        script_content: '',
                        camera_lens: []
                    },
                    source_material: [],
                },
                //是否显示查看附件详情弹窗
                dialogViewScript: false,
            }
        },
        mounted() {
          this.getTrainDetail();
        },
        methods: {
            //获取实训详情
            getTrainDetail() {
                let param = {
                    id: this.$route.query.id
                }
                this.$http.axiosGetBy(this.$api.train, param, (res) => {
                    if (res.code === 200) {
                        this.trainDetailData.train_title = res.data.title;
                        this.trainDetailData.create_time = res.data.create_time;
                        this.trainDetailData.type = res.data.type;
                        if (res.data.introduction) {
                            this.trainDetailData.train_introduction.name = res.data.introduction.name;
                            this.trainDetailData.train_introduction.content = res.data.introduction.content;
                        } else {
                            this.trainDetailData.train_introduction.name = '实训简介';
                            this.trainDetailData.train_introduction.content = '暂无简介';
                        }
                        if (res.data.target) {
                            this.trainDetailData.train_target.name = res.data.target.name;
                            this.trainDetailData.train_target.content = res.data.target.content;
                        } else {
                            this.trainDetailData.train_target.name = '实训目标';
                            this.trainDetailData.train_target.content = '暂无目标';
                        }
                        if (res.data.requirement) {
                            this.trainDetailData.train_requirement.name = res.data.requirement.name;
                            this.trainDetailData.train_requirement.content = res.data.requirement.content;
                        } else {
                            this.trainDetailData.train_requirement.name = '实训要求';
                            this.trainDetailData.train_requirement.content = '暂无要求';
                        }
                        if (res.data.determined_column) {
                            this.trainDetailData.train_determined = res.data.determined_column;
                            if (res.data.determined_column.length > 0) {
                                if (res.data.determined_column.length === 1) {
                                    this.trainDetailData.train_determined_one.name = res.data.determined_column[0].name;
                                    this.trainDetailData.train_determined_one.content = res.data.determined_column[0].content;
                                }
                                if (res.data.determined_column.length === 2) {
                                    this.trainDetailData.train_determined_two.name = res.data.determined_column[1].name;
                                    this.trainDetailData.train_determined_two.content = res.data.determined_column[1].content;
                                }
                            }
                        }
                        this.trainDetailData.train_content.name = res.data.content.name;
                        this.trainDetailData.train_content.content = res.data.content.content;
                        this.trainDetailData.train_evaluete = res.data.evaluate;
                        this.trainDetailData.script_id = res.data.script_id;
                        if (res.data.script) {
                            this.trainDetailData.scriptDetail = res.data.script;
                        }
                        if (res.data.source_material) {
                            if (res.data.source_material.length > 0) {
                                this.trainDetailData.source_material = res.data.source_material;
                            }
                        }
                    } else {
                        this.$message.error(res.message);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //返回
            goToTrainList() {
                this.$router.push({
                    path: '/admin/train/list'
                })
            },
            //下载附件
            downLoadTrainAnnex(item) {
                window.location.href = item.url;
            },
            //查看脚本详情
            viewScriptDetail() {
                this.dialogViewScript = true;

            }
        }
    }
</script>

<style scoped lang="scss">
    .train-detail-content {
        flex: 1;
        width: 1%;
        display: flex;
        flex-direction: column;
        .train-detail-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 15px;
            ::v-deep .el-button--primary {
                padding: 10px 25px;
                color: #FFF;
                background-color: #1E33E4;
                border-color: #1E33E4;
            }
            .interactive-breadcrumb {
                margin-bottom: 0;
            }
        }
        .train-detail-body {
            flex: 1;
            display: flex;
            flex-direction: column;
            height: 1%;
            background-color: #fff;
            padding-left: 20px;
            ::v-deep .el-scrollbar {
                height: 100%;
            }
            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }
            .body-header {
                height: 72px;
                line-height: 72px;
                border-bottom: 1px solid #E5E5E5;
                margin-right: 20px;
                box-sizing: border-box;
                font-size: 16px;
                font-weight: 500;
                color: #333;
                span.text {
                    display: block;
                    width: 100%;
                    height: 100%;
                    padding: 0 20px 0 0;
                    overflow: hidden;
                    text-overflow:ellipsis;
                    white-space: nowrap;
                    box-sizing: border-box;
                }
            }
            .task-info {
                display: flex;
                justify-content: space-between;
                padding: 20px 0;
                margin-right: 20px;
                border-bottom: 1px solid #E5E5E5;
                ul {
                    font-size: 14px;
                    color: #666666;
                    li {
                        margin-bottom: 10px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
            .task-require {
                padding: 20px 0;
                margin-right: 20px;
                border-bottom: 1px solid #E5E5E5;
                .title {
                    margin-bottom: 15px;
                    font-size: 16px;
                    .left-icon {
                        display: inline-block;
                        width: 3px;
                        height: 10px;
                        background-color: #1E33E4;
                        margin-right: 6px;
                    }
                }
                .require-content {
                    color: #666;
                    font-size: 14px;
                }
            }
            .task-annex {
                padding: 20px 0;
                margin-right: 20px;
                border-bottom: 1px solid #E5E5E5;
                .title {
                    margin-bottom: 15px;
                    font-size: 16px;
                    .left-icon {
                        display: inline-block;
                        width: 3px;
                        height: 10px;
                        background-color: #1E33E4;
                        margin-right: 6px;
                    }
                }
                .annex-content {
                    display: flex;
                    justify-content: start;
                    flex-wrap: wrap;
                    li {
                        margin-bottom: 15px;
                        width: 50%;
                        .file-name {
                            float: left;
                            display: block;
                            max-width: 300px;
                            margin-right: 30px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            cursor: pointer;
                            border-bottom:1px solid transparent;
                            i {
                                color: #409EFF;
                            }
                        }
                        .down-progress {
                            display: flex;
                            align-items: center;
                            .download {
                                color: #2338E6;
                                width: 50px;
                                margin-right: 20px;
                                cursor: pointer;
                                border-bottom:1px solid transparent;
                                .download-bg {
                                    display: block;
                                    width: 20px;
                                    height: 20px;
                                    background-color: #409EFF;
                                    color: #fff;
                                    border-radius: 4px;
                                    line-height: 20px;
                                    text-align: center;
                                    i {
                                        font-size: 14px;
                                    }
                                }
                            }
                        }
                    }
                    .script-name {
                        cursor: pointer;
                        border-bottom:1px solid transparent;
                        &:hover {
                            border-bottom:1px solid #2338E6;
                            color: #2338E6;
                        }
                    }
                }
            }
            .train-evaluete {
                padding: 20px 0;
                margin-right: 20px;
                .title {
                    margin-bottom: 15px;
                    font-size: 16px;
                    .left-icon {
                        display: inline-block;
                        width: 3px;
                        height: 10px;
                        background-color: #1E33E4;
                        margin-right: 6px;
                    }
                }
            }
        }
    }
    ::v-deep.annex-list-dialog {
        border-radius: 6px;
        .el-dialog__header{
            display: none;
        }
        ::v-deep .el-dialog__body {
            padding: 30px;
        }
    }
    .view-script-main{
        display: flex;
        flex-direction: column;
        height: 100%;
        //::v-deep .el-scrollbar__wrap {
        //  overflow-x: hidden;
        //}
        .view-script-item-top{
            display: flex;
            //align-items: center;
            .view-script-img{
                width:390px;
                height:224px;
                margin-right:30px;
                img{
                    max-width:390px;
                    max-height:224px;
                    border-radius: 10px;
                }
            }
            .view-script-right{
                flex: 1;
                width: 1%;
                .view-script-name{
                    margin-top: 10px;
                    font-size: 18px;
                    color: #333;
                }
                .view-script-fenlei{
                    font-size: 16px;
                    color: #666;
                    margin-top: 30px;
                }
                .view-script-jianjie{
                    font-size: 16px;
                    color: #666;
                    margin-top: 8px;
                    line-height: 30px;
                }
            }
        }
        .view-script-body{
            display: flex;
            flex-direction: column;
            border: 2px solid #584EEE;
            //box-shadow: 0px 10px 0px 0px #ECEBFF;
            border-radius:20px;
            margin:48px 0 30px;
            .view-script-body-title{
                width: 160px;
                height: 32px;
                background: #584EEE;
                border-radius: 16px;
                line-height: 32px;
                text-align: center;
                color: #fff;
                margin: -12px 0 0 24px;
                position: relative;
                &:before{
                    content: "";
                    background: #fff;
                    -webkit-border-radius: 50%;
                    border-radius: 50%;
                    width: 8px;
                    height: 8px;
                    position: absolute;
                    top: 12px;
                    left: 20px
                }
                &:after{
                    content: "";
                    background: #fff;
                    -webkit-border-radius: 50%;
                    border-radius: 50%;
                    width: 8px;
                    height: 8px;
                    position: absolute;
                    top: 12px;
                    right: 20px
                }
            }
            .view-script-body-content{
                display: flex;
                min-height: 100px;
                padding-left: 22px;
            }
        }

        ::v-deep.el-table{
            .warning-row {
                background: #ECEBFF;
            }
        }
        .dialog-footer {
            text-align: center;
            .el-button {
                padding: 10px 36px;
                margin-bottom: 0;
            }
            .el-button--primary {
                background-color: #2338E6;
                border: 1px solid #2338E6;
                &:hover {
                    background-color: #1b2dbf;
                    border: 1px solid #1b2dbf;
                }
            }
        }
    }
    .script-dialog-table{
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        height: 265px;
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }
        .dialog-table-row{
            display: flex;
            &:nth-child(odd){
                background: #DEDCFD;
            }
            &:nth-child(even){
                background: #F8F7FF;
            }
            &:nth-child(1){
                .dialog-table-column{
                    font-size: 14px;
                    color: #222222;
                    font-weight: 400;
                }
            }
            .dialog-table-column{
                display: flex;
                flex: 1;
                width: 1%;
                justify-content: center;
                align-items: center;
                height: 50px;
            }
        }
    }
</style>